import { DocsLightTheme } from "@gadgetinc/widgets/src/DocsTheme";
import { useStyletron } from "baseui";
import { ParagraphLarge } from "baseui/typography";
import React from "react";
import GuideIcon from "../assets/images/API-icon.svg";
import APIIcon from "../assets/images/Docs.svg";
import CodeIcon from "../assets/images/Run-code-snippet.svg";
import type { DocsInterfaceProps } from "../src/chrome/DocsInterface";
import { DocsInterface } from "../src/chrome/DocsInterface";
import { SplashCard, SplashCardGrid, SplashContentContainer, SplashHeading, colors } from "../src/components/SplashPage";

const Icon = (props: { src: string }) => (
  <img
    src={props.src}
    alt="icon"
    style={{
      height: "32px",
      width: "32px",
    }}
  />
);

const HomePage = (props: DocsInterfaceProps) => {
  const [css, $theme] = useStyletron();

  return (
    <DocsInterface {...props} showLeftSideNav={false}>
      <div
        className={css({
          backgroundColor: "#f9f9f9",
        })}
      >
        <SplashContentContainer
          $style={{
            paddingTop: $theme.sizing.scale800,
            paddingBottom: $theme.sizing.scale800,
          }}
        >
          <SplashHeading>Gadget developer documentation</SplashHeading>
          <ParagraphLarge
            $style={{
              paddingTop: $theme.sizing.scale600,
              maxWidth: "700px",
            }}
          >
            Learn the fundamentals of the Gadget framework with our guides. The guides will teach you core Gadget concepts, and show you how
            to build full-stack web apps with Gadget.
          </ParagraphLarge>
        </SplashContentContainer>
      </div>
      <SplashContentContainer
        $style={{
          paddingTop: $theme.sizing.scale600,
          paddingBottom: $theme.sizing.scale600,
          [DocsLightTheme.mediaQuery.xsmall]: {
            paddingTop: $theme.sizing.scale600,
            paddingBottom: $theme.sizing.scale600,
          },
        }}
      >
        <SplashCardGrid>
          <SplashCard
            title="Guides"
            body="Concepts and tutorials that show you how to build an app in Gadget."
            copy="View guides →"
            href="/guides"
            color={colors.blue}
            icon={<Icon src={GuideIcon} />}
          />
          <SplashCard
            title="Generated API"
            body="Detailed documentation on how to use your Gadget generated API."
            copy="View your API →"
            href={`/api/${props.currentApp.slug}/development`}
            color={colors.purple}
            icon={<Icon src={APIIcon} />}
          />
          <SplashCard
            title="Reference"
            body="An overview of the variety of packages and tools for your Gadget application."
            copy="View the reference →"
            href="/reference"
            color={colors.grey}
            icon={<Icon src={CodeIcon} />}
          />
        </SplashCardGrid>
      </SplashContentContainer>
    </DocsInterface>
  );
};

export default HomePage;
